/* In your main CSS file or at the top of your component file */
@import 'react-responsive-carousel/lib/styles/carousel.min.css';

@keyframes transitionColor {
    from {
        background-color: grey;
    }

    to {
        background-color: black;
    }
}

.first-container {
    background-color: black;
    color: aliceblue;
    animation: transitionColor 1s forwards;
}

.third-container {
    background-color: black;
    animation: transitionColor 1s forwards;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.slogan,
.intro-video,
.sign-up {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.slogan{
    color: #fff;
    font-family: Krona One, sans-serif;
}
.slogan ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 10px 0px;
    padding: 0;
}

.slogan li {
    margin: 0 10px;
    padding: 0;
}

.intro-video h1 {
    text-align: center;
    width: 60%;
}

.slogan0 {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(275deg, #fff, #fff 59%);
    color: #fff;
    font-family: Krona One, sans-serif;
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.125;
}
.slogan2{
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(275deg, #fff, #fff 59%);
    color: #fff;
    font-family: Krona One, sans-serif;
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.125; 
}
.slogan1 {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(275deg, #d4aa4f, #d4aa4f 59%);
    color: #d4aa4f;
    font-family: Krona One, sans-serif;
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.125;
}
.slogan3 {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(275deg, #d4aa4f, #d4aa4f 59%);
    color: #d4aa4f;
    font-family: Krona One, sans-serif;
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.125;
    text-align: center;
}
.second-container h2 {
    text-align: center;
    font-size: 2rem;
    width: 80%;
    margin: 10px auto;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    font-size: 1.5em;
    line-height: 2;
    font-weight: 500;
}

.description {
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    font-size: 1em;
    line-height: 1.5;
    font-weight: 500;
}

@media only screen and (max-width: 425px) {

    /*Small smartphones [325px -> 425px]*/
    .intro-video h1 {
        width: 100%;
    }

    .slogan0,
    .slogan1 {
        font-size: 1.5rem;
    }

    .slogan2,
    .slogan3 {
        font-size: 1rem;
    }
    .second-container h2 {
        font-size: 1rem;
        width: 100%;
    }

}

@media only screen and (max-width: 1200px) {

    /*Tablets [601px -> 1200px]*/
    .intro-video h1 {
        width: 100%;
    }

    .second-container h2 {
        font-size: 1.5rem;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {

    /*Big smartphones [426px -> 600px]*/
    .intro-video h1 {
        width: 100%;
    }

    .second-container h2 {
        font-size: 1.2rem;
        width: 100%;
    }
}

.sign-up {
    margin-top: 25px;
}

video {
    width: 100%;
    max-width: 1000px;
}

@media (max-width: 600px) {
    video {
        max-width: 300px;
    }
}

.second-container {
    background-color: #212224;
    padding: 2% 10%;
    width: 80%;
    margin: 0 auto;
}



.second-container div {
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 10px;
}


.second-container span {
    color: #d4aa4f;
}
.forth-container{
    background-color: #212224;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
    flex-wrap: wrap;
}