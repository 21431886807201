.boxcourse{
    background-color: rgb(255, 255, 255);
    /* height: 100vh; */
    height:fit-content
}
body:before{
    content: normal !important;
}
.courseSMC{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}
.ytp-youtube-button{
    display: none;
}
.video-react-video{
    width: 100% !important;
}
.courses{
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
}

@media only screen and (max-width: 1200px){
    /*Tablets [601px -> 1200px]*/
    .courseSMC{
        width: 60%;
    }
    .iphone{
        width: 90%;
    }
}
@media only screen and (max-width: 600px){
	/*Big smartphones [426px -> 600px]*/
    .courseSMC{
        width: 80%;
    }
    .iphone{
        width: 90%;
    }
}
@media only screen and (max-width: 425px){
	/*Small smartphones [325px -> 425px]*/
    .courseSMC{
        width: 90%;
    }
    .iphone{
        width: 90%;
    }
}

.search{
    width: 30%;
    margin-left: 70%;
}
.inputs{
    width:600px;
    margin:0 auto;
    display:flex;
    justify-content:center;
    align-items:center;
}
.textinput{
    width: 300px;
    margin: 10px 5px !important;
}
.textinpu{
    width: 600px;
    margin: 10px 5px !important;
}
.contentcourse{
    text-align: justify;
}
.item{
    display:grid;
    grid-template-columns:20% 60% 20%;
    width:30%; 
    /* height: 180px; */
    margin:10px auto;
    border-radius:10px;
    background-color:#fff;
    box-shadow:1px 1px 3px #000;
}
.itemAdmin{
    width:400px; 
    height: fit-content;
    margin:10px auto;
    border-radius:10px;
    background-color:#fff;
    box-shadow:1px 1px 3px #000;
    padding: 10px;
}
.itemP{
    display:grid;
    grid-template-columns:40% 60%;
    width:350px; 
    margin:10px auto;
    border-radius:10px;
    background-color:#fff;
    box-shadow:1px 1px 3px #000;
}
.items{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.hummm p{
    margin:0 10px;
    padding:0;
    text-align: left;
    font-size: 12px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 0.4em;
    background-color: #171717;
    border-radius: 25px;
    transition: .4s ease-in-out;
  }
  
  .form:hover {
    transform: scale(1.05);
    border: 1px solid black;
  }
  
  #heading {
    text-align: center;
    margin: 2em;
    color: rgb(255, 255, 255);
    font-size: 1.2em;
  }
  
  .field {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0.5em !important;
    border-radius: 25px !important;
    padding: 0.6em !important;
    border: none !important;
    outline: none !important;
    color: white !important;
    background-color: #171717 !important;
    box-shadow: inset 2px 5px 10px rgb(5, 5, 5) !important;
  }
  
  .input-icon {
    height: 1.3em;
    width: 1.3em;
    fill: white;
  }
  
  .input-field {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    color: #d3d3d3;
  }
  
  .form .btn {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 2.5em;
  }
  
  .button1 {
    padding: 0.5em;
    padding-left: 1.1em;
    padding-right: 1.1em;
    border-radius: 5px;
    margin-right: 0.5em;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #252525;
    color: white;
  }
  
  .button1:hover {
    background-color: black;
    color: white;
  }
  
  .button2 {
    padding: 0.5em;
    padding-left: 2.3em;
    padding-right: 2.3em;
    border-radius: 5px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #252525;
    color: white;
  }
  
  .button2:hover {
    background-color: black;
    color: white;
  }
  
  .button3 {
    margin-bottom: 3em;
    padding: 0.5em;
    border-radius: 5px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #252525;
    color: white;
  }
  
  .button3:hover {
    background-color: red;
    color: white;
  }

/* .incorrect {
    background-color: black !important;
}
.correct {
    background-color: black !important;
} */





@media (max-width:1000px) {
    .textinpu{
        width: 80%;
    }
    .textinput{
        width: 80%;
    }
    .inputs{
        width:100%;
        margin:0 auto;
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction: column;
    }
    .item{
        width:100%; 
    }
    .items{
        width:95%; 
    }
    .search{
        width: 100%;
        margin-left: 0;
    }
}

